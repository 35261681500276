<template>
  <div id="neutralElectrodeFactoryReport">
    <el-dialog
      :title="neutralElectrodeFactoryReportFormTitle"
      width="1200px"
      :visible.sync="neutralElectrodeFactoryReportDialogVisible"
      :close-on-click-modal="false"
      @close="neutralElectrodeFactoryReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="neutralElectrodeFactoryReportFormRef"
        :model="neutralElectrodeFactoryReportForm"
        :rules="neutralElectrodeFactoryReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="productName">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.productName"
                readonly
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.quantity"
                placeholder="请输入数量"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="抽检数量" prop="inspectionQuantity">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.inspectionQuantity"
                placeholder="请输入抽检数量"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="neutralElectrodeFactoryReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="检验依据" prop="testBased">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.testBased"
                placeholder="请输入检验依据"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="reportDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="claim"
              title="要求条款"
              width="80"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="inspectionItem"
              title="检验项目"
              width="150"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="standard"
              title="标准要求"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="sampleQuantity"
              title="抽样数"
              width="100"
              :edit-render="{name: '$input', props: {type: 'number', clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="实测结果"
              width="120"
              :edit-render="{
                name: '$select',
                props: {clearable: true},
                options: [
                  { value: 1, label: '符合要求' },
                  { value: 0, label: '不符合要求' }
                ]
              }"
            />
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="检验结论" prop="conclusion">
              <el-radio-group
                v-model="neutralElectrodeFactoryReportForm.conclusion"
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="neutralElectrodeFactoryReportForm.reviewResult"
                :disabled="neutralElectrodeFactoryReportFormTitle !== '复核一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="neutralElectrodeFactoryReportForm.approvalResult"
                :disabled="neutralElectrodeFactoryReportFormTitle !== '批准一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="检验记录" prop="scheduleInspectionRecord">
              <el-input
                v-model="neutralElectrodeFactoryReportForm.scheduleInspectionRecord"
                placeholder="请输入检验记录"
                type="textarea"
                :rows="12"
                :disabled="neutralElectrodeFactoryReportFormTitle !== '新增一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '修改一次性使用中性电极出厂检验报告'
                  && neutralElectrodeFactoryReportFormTitle !== '一次性使用中性电极出厂检验报告详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="neutralElectrodeFactoryReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="neutralElectrodeFactoryReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="neutralElectrodeFactoryReportFormTitle === '一次性使用中性电极出厂检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="批号">
        <el-input v-model="searchForm.batchNo" placeholder="请输入批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['NEUTRAL_OUT_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="neutralElectrodeFactoryReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="testBased" label="检验依据" />
      <el-table-column label="检验结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate">{{ scope.row.inspectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="scheduleInspectionRecord" label="检验记录" /> -->
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['NEUTRAL_OUT_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['NEUTRAL_OUT_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['NEUTRAL_OUT_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['NEUTRAL_OUT_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="neutralElectrodeFactoryReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addNeutralElectrodeFactoryReport,
  deleteNeutralElectrodeFactoryReport,
  updateNeutralElectrodeFactoryReport,
  selectNeutralElectrodeFactoryReportInfo,
  selectNeutralElectrodeFactoryReportList,
  reviewNeutralElectrodeFactoryReport,
  approvalNeutralElectrodeFactoryReport
} from '@/api/quality/neutralElectrodeFactoryReport'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      neutralElectrodeFactoryReportDialogVisible: false,
      neutralElectrodeFactoryReportFormTitle: '',
      neutralElectrodeFactoryReportForm: {
        id: '',
        formNo: '',
        productName: '一次性使用中性电极',
        spec: '',
        batchNo: '',
        quantity: '',
        inspectionQuantity: '',
        testBased: '',
        reportDate: '',
        conclusion: '',
        remarks: '',
        reviewResult: '',
        approvalResult: '',
        scheduleInspectionRecord: '',
        status: '',
        reportDetailJson: '',
        taskId: ''
      },
      neutralElectrodeFactoryReportFormRules: {
        batchNo: [{ required: true, message: '批号不能为空', trigger: ['blur', 'change']}]
      },
      neutralElectrodeFactoryReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        batchNo: ''
      },
      reportDetailList: [
        { claim: '2.1.1', inspectionItem: '外观', standard: '导电胶应为无色或浅色透明粘胶', result: '' },
        { claim: '2.1.2', inspectionItem: '外观', standard: '海绵应平整光洁、无裂纹', result: '' },
        { claim: '2.1.3', inspectionItem: '外观', standard: '有线中性电极的电线应无破损，表面光洁、无杂质', result: '' },
        { claim: '2.2', inspectionItem: '规格', standard: '应符合产品技术要求中表1和表2的要求', result: '' },
        { claim: '2.3', inspectionItem: '电缆线的连接牢固度', standard: '有线电极的电线与电极板的连接应牢固，应能承受40N拉力，历时15秒不得有拉脱', result: '' },
        { claim: '2.6.1', inspectionItem: '微生物指标', standard: '导电胶表面不得检出大肠菌群、绿脓杆菌、金黄色葡萄球菌、溶血性链球菌；真菌菌落总数≤100cfu/g 细菌菌落总数≤100cfu/g', result: '' }
      ]
    }
  },
  created () {
    selectNeutralElectrodeFactoryReportList(this.searchForm).then(res => {
      this.neutralElectrodeFactoryReportPage = res
    })
  },
  methods: {
    neutralElectrodeFactoryReportDialogClose () {
      this.$refs.neutralElectrodeFactoryReportFormRef.resetFields()
      this.reportDetailList = [
        { claim: '2.1.1', inspectionItem: '外观', standard: '导电胶应为无色或浅色透明粘胶', result: '' },
        { claim: '2.1.2', inspectionItem: '外观', standard: '海绵应平整光洁、无裂纹', result: '' },
        { claim: '2.1.3', inspectionItem: '外观', standard: '有线中性电极的电线应无破损，表面光洁、无杂质', result: '' },
        { claim: '2.2', inspectionItem: '规格', standard: '应符合产品技术要求中表1和表2的要求', result: '' },
        { claim: '2.3', inspectionItem: '电缆线的连接牢固度', standard: '有线电极的电线与电极板的连接应牢固，应能承受40N拉力，历时15秒不得有拉脱', result: '' },
        { claim: '2.6.1', inspectionItem: '微生物指标', standard: '导电胶表面不得检出大肠菌群、绿脓杆菌、金黄色葡萄球菌、溶血性链球菌；真菌菌落总数≤100cfu/g 细菌菌落总数≤100cfu/g', result: '' }
      ]
    },
    neutralElectrodeFactoryReportFormSubmit () {
      if (this.neutralElectrodeFactoryReportFormTitle === '一次性使用中性电极出厂检验报告详情') {
        this.neutralElectrodeFactoryReportDialogVisible = false
        return
      }
      this.$refs.neutralElectrodeFactoryReportFormRef.validate(async valid => {
        if (valid) {
          if (this.neutralElectrodeFactoryReportFormTitle === '新增一次性使用中性电极出厂检验报告') {
            this.neutralElectrodeFactoryReportForm.id = ''
            this.neutralElectrodeFactoryReportForm.status = 1
            this.neutralElectrodeFactoryReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addNeutralElectrodeFactoryReport(this.neutralElectrodeFactoryReportForm)
          } else if (this.neutralElectrodeFactoryReportFormTitle === '修改一次性使用中性电极出厂检验报告') {
            this.neutralElectrodeFactoryReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateNeutralElectrodeFactoryReport(this.neutralElectrodeFactoryReportForm)
          } else if (this.neutralElectrodeFactoryReportFormTitle === '复核一次性使用中性电极出厂检验报告') {
            this.neutralElectrodeFactoryReportForm.status = 2
            await reviewNeutralElectrodeFactoryReport(this.neutralElectrodeFactoryReportForm)
          } else if (this.neutralElectrodeFactoryReportFormTitle === '批准一次性使用中性电极出厂检验报告') {
            this.neutralElectrodeFactoryReportForm.status = 3
            await approvalNeutralElectrodeFactoryReport(this.neutralElectrodeFactoryReportForm)
          }
          this.neutralElectrodeFactoryReportPage = await selectNeutralElectrodeFactoryReportList(this.searchForm)
          this.neutralElectrodeFactoryReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.neutralElectrodeFactoryReportFormTitle = '新增一次性使用中性电极出厂检验报告'
      this.neutralElectrodeFactoryReportDialogVisible = true
      this.neutralElectrodeFactoryReportForm.formNo = 'CJ' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteNeutralElectrodeFactoryReport(row.id)
        if (this.neutralElectrodeFactoryReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.neutralElectrodeFactoryReportPage = await selectNeutralElectrodeFactoryReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.neutralElectrodeFactoryReportFormTitle = '修改一次性使用中性电极出厂检验报告'
      this.neutralElectrodeFactoryReportDialogVisible = true
      this.selectNeutralElectrodeFactoryReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.neutralElectrodeFactoryReportFormTitle = '复核一次性使用中性电极出厂检验报告'
      this.neutralElectrodeFactoryReportDialogVisible = true
      this.selectNeutralElectrodeFactoryReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.neutralElectrodeFactoryReportFormTitle = '批准一次性使用中性电极出厂检验报告'
      this.neutralElectrodeFactoryReportDialogVisible = true
      this.selectNeutralElectrodeFactoryReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.neutralElectrodeFactoryReportFormTitle = '一次性使用中性电极出厂检验报告详情'
      this.neutralElectrodeFactoryReportDialogVisible = true
      this.selectNeutralElectrodeFactoryReportInfoById(row.id)
    },
    selectNeutralElectrodeFactoryReportInfoById (id) {
      selectNeutralElectrodeFactoryReportInfo(id).then(res => {
        this.neutralElectrodeFactoryReportForm.id = res.id
        this.neutralElectrodeFactoryReportForm.formNo = res.formNo
        this.neutralElectrodeFactoryReportForm.productName = res.productName
        this.neutralElectrodeFactoryReportForm.spec = res.spec
        this.neutralElectrodeFactoryReportForm.batchNo = res.batchNo
        this.neutralElectrodeFactoryReportForm.quantity = res.quantity
        this.neutralElectrodeFactoryReportForm.inspectionQuantity = res.inspectionQuantity
        this.neutralElectrodeFactoryReportForm.testBased = res.testBased
        this.neutralElectrodeFactoryReportForm.reportDate = res.reportDate
        this.neutralElectrodeFactoryReportForm.conclusion = res.conclusion
        this.neutralElectrodeFactoryReportForm.remarks = res.remarks
        this.neutralElectrodeFactoryReportForm.reviewResult = res.reviewResult
        this.neutralElectrodeFactoryReportForm.approvalResult = res.approvalResult
        this.neutralElectrodeFactoryReportForm.scheduleInspectionRecord = res.scheduleInspectionRecord
        this.neutralElectrodeFactoryReportForm.status = res.status
        this.neutralElectrodeFactoryReportForm.taskId = res.taskId
        this.reportDetailList = res.reportDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectNeutralElectrodeFactoryReportList(this.searchForm).then(res => {
        this.neutralElectrodeFactoryReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectNeutralElectrodeFactoryReportList(this.searchForm).then(res => {
        this.neutralElectrodeFactoryReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectNeutralElectrodeFactoryReportList(this.searchForm).then(res => {
        this.neutralElectrodeFactoryReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['NEUTRAL_OUT_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['NEUTRAL_OUT_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `一次性使用中性电极出厂检验报告${this.neutralElectrodeFactoryReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
