import axios from '@/common/axios'
import qs from 'qs'

export function addNeutralElectrodeFactoryReport (data) {
  return axios({
    method: 'post',
    url: '/quality/neutralReport/add',
    data: qs.stringify(data)
  })
}

export function deleteNeutralElectrodeFactoryReport (id) {
  return axios({
    method: 'delete',
    url: '/quality/neutralReport/delete/' + id
  })
}

export function updateNeutralElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/neutralReport/update',
    data: qs.stringify(data)
  })
}

export function selectNeutralElectrodeFactoryReportInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/neutralReport/info/' + id
  })
}

export function selectNeutralElectrodeFactoryReportList (query) {
  return axios({
    method: 'get',
    url: '/quality/neutralReport/list',
    params: query
  })
}

export function reviewNeutralElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/neutralReport/updateReview',
    data: qs.stringify(data)
  })
}

export function approvalNeutralElectrodeFactoryReport (data) {
  return axios({
    method: 'put',
    url: '/quality/neutralReport/updateApprove',
    data: qs.stringify(data)
  })
}
